import { jwtDecode } from 'jwt-decode';
import { AUTH_TOKEN_STORAGE_KEY, pageURLs } from '@/constants';
import { Layout } from '@/components/layout';
import { ThemeProvider } from '@/components/theme-provider';

import { useSetAtom } from 'jotai';
import { tokenClaimsAtom } from '@/atoms';

import { createRoutesFromElements, createBrowserRouter, RouterProvider, Route, Navigate } from 'react-router-dom';

const App = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const tokenClaims = authToken === null ? null : jwtDecode(authToken);
  const setClaims = useSetAtom(tokenClaimsAtom);
  setClaims(tokenClaims);
  const routes = createRoutesFromElements(
    <>
      <Route path={pageURLs.login.base} lazy={() => import('@/pages/login/index')} />
      <Route path={pageURLs.login.withoutSlug} lazy={() => import('@/pages/login/without-slug')} />
      <Route path={pageURLs.homePage} element={authToken === null ? <Navigate to={pageURLs.login.withoutSlug} /> : <Layout />}>
        <Route index element={<Navigate to={pageURLs.deliveryReceipts.list} />} />
        <Route path={pageURLs.carts} lazy={() => import('@/pages/work-in-progress')} />
        <Route path={pageURLs.medications} lazy={() => import('@/pages/medications')} />
        <Route path={pageURLs.deliveryReceipts.list}>
          <Route index lazy={() => import('@/pages/delivery-receipts/index')} />
          <Route path={pageURLs.deliveryReceipts.create} lazy={() => import('@/pages/delivery-receipts/create')} />
          <Route path={pageURLs.deliveryReceipts.edit} lazy={() => import('@/pages/delivery-receipts/edit')} />
        </Route>
        <Route path={pageURLs.inventories} lazy={() => import('@/pages/work-in-progress')} />
        <Route path={pageURLs.customers} lazy={() => import('@/pages/work-in-progress')} />
        <Route path={pageURLs.staff} lazy={() => import('@/pages/work-in-progress')} />
      </Route>
    </>
  );
  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider defaultTheme="light">
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
