import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { ReloadIcon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:border-border',
  {
    variants: {
      variant: {
        default: 'border border-primary-foreground/20 bg-primary text-primary-foreground shadow hover:bg-primary/90',
        reverse: 'border border-primary/80 bg-background text-primary shadow hover:bg-primary-foreground/90',
        success: 'bg-success text-success-foreground shadow hover:bg-success/90',
        warning: 'bg-warning text-warning-foreground shadow hover:bg-warning/90',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline: 'border border-foreground/20 bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground bg-background hover:border-foreground/60',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9 min-h-9 min-w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

function ButtonLoading({ isLoading, ...props }) {
  return (
    <Button {...props} disabled={isLoading || props.disabled}>
      {isLoading && <ReloadIcon className={`${props.children && 'mr-2'} h-4 w-4 animate-spin`} />}
      {props.children}
    </Button>
  );
}
export { Button, ButtonLoading, buttonVariants };
