import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { pageURLs, AUTH_TOKEN_STORAGE_KEY } from '@/constants';
import { useSearchParams, Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { tokenClaimsAtom } from '@/atoms';
import { ToastAction } from '@/components/ui/toast';

const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

const sendAuthenticatedRequest = async ({ url, ...props }) => {
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...props,
  });

  return response;
};

const handleResponseError = ({ response, errorHandler }) => {
  if (response.status === 401) {
    errorHandler({ duration: Infinity, msg: 'Votre session a expiré. Veuillez vous reconnecter.', action: <ReAuthenticateToastAction /> });
  } else if (response.status === 403) {
    errorHandler({ msg: "Vous n'avez pas les permissions pour effectuer cette action." });
  } else if (response.status === 404) {
    errorHandler({ msg: 'La resource que vous recherchez est introuvable.' });
  } else {
    errorHandler({ msg: 'Une erreur est survenue, veuillez réessayer plus tard.' });
  }
};

const ReAuthenticateToastAction = () => {
  const { pharmacySlug } = useAtomValue(tokenClaimsAtom);
  const searchParams = useSearchParams()[0].toString();
  const currentPathname = window.location.pathname;
  const targetUrl = `${pageURLs.login.base.replace(':slug', pharmacySlug)}?next=${currentPathname}${searchParams !== '' ? `&${searchParams}` : ''}`;
  return (
    <ToastAction altText="Se reconnecter">
      <Link to={targetUrl}>Se reconnecter</Link>
    </ToastAction>
  );
};

export { ReAuthenticateToastAction, sendAuthenticatedRequest, handleResponseError, cn };
