import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarSeparator,
  SidebarMenuItem,
} from '@/components/ui/sidebar';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { NavLink } from 'react-router-dom';
import {
  Stethoscope,
  ContactRound,
  ListChecks,
  ShoppingBasket,
  PackageOpen,
  Pill,
  Receipt,
  LogOut,
  Wrench,
  UserRoundCog,
  ChevronUp,
} from 'lucide-react';
import { useAtomValue } from 'jotai';
import { tokenClaimsAtom } from '@/atoms';
import { AUTH_TOKEN_STORAGE_KEY, pageURLs } from '@/constants';

const menuItems = [
  {
    title: 'Bons de livraison',
    url: pageURLs.deliveryReceipts.list,
    icon: PackageOpen,
  },
  {
    title: 'Medicaments',
    url: pageURLs.medications,
    icon: Pill,
  },
  {
    title: 'Panier',
    url: pageURLs.carts,
    icon: ShoppingBasket,
  },
  {
    title: 'Inventaire',
    url: pageURLs.inventories,
    icon: ListChecks,
  },
  {
    title: 'Patients',
    url: pageURLs.customers,
    icon: ContactRound,
  },
  {
    title: 'Staff',
    url: pageURLs.staff,
    icon: Stethoscope,
  },
];

  const signOutFunction = (pharmacySlug) => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    window.location.href = pageURLs.login.base.replace(':slug', pharmacySlug);
  };

const SidebarFooterDropdown = () => {
  const userName = useAtomValue(tokenClaimsAtom)?.firstName;
  const { pharmacySlug } = useAtomValue(tokenClaimsAtom);

  const footerMenuItems = [
    // {
    //   title: 'Mon compte',
    //   icon: Wrench,
    //   click: () => {
    //     console.log('mon compte');
    //   },
    // },
    { title: 'Se déconnecter', icon: LogOut, click: () => signOutFunction(pharmacySlug) },
  ];
  return (
    <SidebarMenu>
      <SidebarMenuItem className="ml-2 py-2 justify-center items-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton variant="outline">
              <UserRoundCog /> Bonjour, {userName}
              <ChevronUp className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="top" className="min-w-[--radix-popper-anchor-width] bg-background text-sidebar-foreground">
            {footerMenuItems.map((item) => (
              <DropdownMenuItem key={item.title} className="hover:!bg-sidebar/60" onClick={item.click}>
                <item.icon size={15} />
                <span>{item.title}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

const AppSidebar = () => {
  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <span className="w-full text-center text-2xl font-semibold">
          L<span className="group-data-[collapsible=icon]:hidden">ivy</span>
        </span>
      </SidebarHeader>
      <SidebarSeparator />
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item) => (
                <SidebarMenuItem className="ml-2 py-2 justify-center items-center" key={item.title}>
                  <NavLink to={item.url}>
                    {({ isActive }) => (
                      <SidebarMenuButton
                        isActive={isActive}
                        className="[&>svg]:size-6 group-data-[collapsible=icon]:justify-center font-semibold"
                        asChild
                      >
                        <span>
                          <item.icon />
                          <span className="group-data-[collapsible=icon]:hidden">{item.title}</span>
                        </span>
                      </SidebarMenuButton>
                    )}
                  </NavLink>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarFooterDropdown />
      </SidebarFooter>
    </Sidebar>
  );
};

export { AppSidebar };
