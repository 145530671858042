const AUTH_TOKEN_STORAGE_KEY = 'auth-token';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;
const SEARCH_TIMEOUT_IN_MS = 1000;
const RESULTS_PER_PAGE = 25;
const ENTER_KEY_CODE = 13;
const MAX_ALLOWED_INT_VALUE = 999999;

const apiURLs = {
  auth: {
    login: `${BASE_API_URL}/auth/login/`,
  },
  deliveryReceipts: {
    create: `${BASE_API_URL}/api/delivery-receipts/`,
    list: `${BASE_API_URL}/api/delivery-receipts/`,
    retrieve: `${BASE_API_URL}/api/delivery-receipts/:id/`,
    edit: `${BASE_API_URL}/api/delivery-receipts/:id/`,
    approve: `${BASE_API_URL}/api/delivery-receipts/:id/approve/`,
    search: `${BASE_API_URL}/api/delivery-receipts/search/?:queryParams`,
  },
  medications: {
    scan: `${BASE_API_URL}/api/medications/scan/:barCode/`,
    search: `${BASE_API_URL}/api/medications/search/?:queryParams`,
    list: `${BASE_API_URL}/api/medications/`,
  },
  vendors: {
    list: `${BASE_API_URL}/api/vendors/`,
  },
};

const pageURLs = {
  homePage: '/',
  login: {
    base: '/login/:slug',
    withoutSlug: '/login',
  },
  carts: '/carts',
  medications: '/medications',
  deliveryReceipts: {
    list: '/delivery-receipts',
    create: '/delivery-receipts/create',
    edit: '/delivery-receipts/:id/edit',
  },
  inventories: '/inventories',
  customers: '/customers',
  staff: '/staff',
};

export { AUTH_TOKEN_STORAGE_KEY, ENTER_KEY_CODE, RESULTS_PER_PAGE, SEARCH_TIMEOUT_IN_MS, SENTRY_DSN, MAX_ALLOWED_INT_VALUE, apiURLs, pageURLs };
